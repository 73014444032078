// Variables
$brand-color: #db2777;
$text-color: #333;
$meta-color: #666;
$border-color: #eee;
$base-font-size: 16px;
$small-font-size: 14px;  // Added for smaller text elements
$base-line-height: 1.8;

// Font stacks
$system-fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
$cjk-fonts: "Microsoft YaHei", "微软雅黑", "SimSun", "宋体";
$code-fonts: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;

@import "minima";

// Your existing body and basic styles...

.post-content {
  font-size: $base-font-size;
  line-height: $base-line-height;
  letter-spacing: 0.01em;

  // Adjusted blockquote styling
  blockquote {
    border-left: 4px solid $brand-color;
    padding: 0.5em 1em;
    background: rgba($brand-color, 0.05);
    margin: 1.5em 0;
    font-size: $small-font-size;
    line-height: 1.6;

    p {
      margin: 0.5em 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // Improved code blocks
  pre, code {
    font-family: $code-fonts;
  }

  // Inline code
  code {
    font-size: 0.875em; // 14px relative to parent
    padding: 0.2em 0.4em;
    border-radius: 3px;
    background: #f8f9fa;
  }

  // Code blocks
  pre {
    padding: 1em;
    margin: 1.5em 0;
    overflow-x: auto;
    background: #f8f9fa;
    border-radius: 4px;
    border: 1px solid $border-color;

    code {
      padding: 0;
      font-size: $small-font-size;
      line-height: 1.6;
      background: none;
      border: none;
      letter-spacing: normal;
    }
  }
}

// Responsive adjustments
@media screen and (max-width: 600px) {
  .post-content {
    blockquote, pre code {
      font-size: 13px;
    }

    code {
      font-size: 0.85em;
    }
  }
}
